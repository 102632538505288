<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card class="pt-2">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fa-solid fa-signal" aria-hidden="true" style="font-size: 21px; margin-right: 10px; color: var(--iq-primary);"></i>
              {{ cvCardTitle }}: <span class="report_name"> {{ action_module_name.module_name }}</span>
            </h4>
            <span>{{getFormattedDate(whereFilter.dateRange.startDate)}}</span> <span> /  {{getFormattedDate(whereFilter.dateRange.endDate)}}</span>
          </template>
          <template v-slot:body>
            <b-row :key="refreshTotalCount">
              <div class="w-100 mb-3 multiple_boxes_row ">
               <div class="ml-2">
                <div class="boxs" :style="'background:'+getBgFor(0)">
                  <iq-card class="btn mb-1 multiCardboxs">
                    <span  class="textintworow text-white">
                      Total
                    </span>
                    <ul>
                      <li class="badge badge-light">Opens<span> {{ total_opens }} </span></li>
                      <li class="badge badge-light">Unique User<span> {{ total_uniqueuser }} </span></li>
                      <li class="badge badge-light">Open per user<span> {{ total_open_per_unique_user }} </span></li>
                    </ul>
                  </iq-card>
                </div>
              </div>
              <div class="ml-2" v-for="(item, index) of total_open_count_list" :key="index">
                <div class="boxs" :style="'background:'+getBgFor(index+1)">
                  <iq-card class="btn mb-1 multiCardboxs">
                    <span class="textintworow text-white">
                      {{ item.device_id }}
                    </span>
                    <ul>
                      <li class="badge badge-light">Opens  <span> {{ item.total_open_count }} </span></li>
                      <li class="badge badge-light">Unique User<span> {{ item.unique_user }} </span></li>
                      <li class="badge badge-light">Open per user<span> {{ (item.unique_user == 0) ? "0" : Math.round(item.total_open_count / item.unique_user)}} </span></li>
                    </ul>
                  </iq-card>
                </div>
              </div>
            </div>
            </b-row>
            <b-row class="mb-3">
              <b-col col-xs="12" sm="6" md="6" lg="3" xl="3" class="mb-2">
                <b-form-input v-model="filter" type="search" :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col col-xs="12" sm="6" md="6" lg="3" xl="3" class="mb-2">
                  <multiselect
                    v-model="action_module_name"
                    :tag-placeholder="cvSelectLabel"
                    :placeholder="cvSelectLabel"
                    :searchable="true"
                    label="module_name"
                    track-by="module_name"
                    :options="csModuleNameList"
                    @select="setFilterChange"
                    >
                  </multiselect>
              </b-col>
              <b-col col-xs="12" sm="6" md="6" lg="3" xl="3" class="mb-2">
                <select v-model="whereFilter.dateType" class="form-control" @change="setDateTypeFilterChange">
                  <option value="year">Year</option>
                  <option value="month">Month</option>
                  <option value="date">Day</option>
                </select>
              </b-col>
              <b-col col-xs="12" sm="6" md="6" lg="3" xl="3" class="mb-2">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="float-right" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getAnalyticsListDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12"
                v-if="analytics_trackerObjList && analytics_trackerObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="analytics_trackerObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(opu)="data">
                    {{ (data.item.unique_user == 0) ? "0" : Math.round(data.item.total_open_count / data.item.unique_user)}}
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal v-model="showModelanalyticsTrackerAdd" scrollable :title="cvAddModalHeader" size="xl">
      <analyticsTrackerAdd :propOpenedInModal="true" @emitCloseanalyticsTrackerAddModal="closeanalyticsTrackerAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="analytics_trackerAdd('FULLPAGE')">
          {{ cvbtnModalExpand }}
        </b-button>
        <b-button size="sm" @click="closeanalyticsTrackerAddModal()">
          {{ cvbtnModalCancel }}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelanalyticsTrackerDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{ cvDelMessage }}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelanalyticsTrackerDelete = false">
          {{ cvbtnModalCancel }}
        </b-button>
        <b-button size="sm" variant="primary" @click="analytics_trackerDelete()">
          {{ cvbtnModalDelOk }}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style lang="scss" scoped>
.multiCardboxs {
  display: table-caption;
  padding: 10px;
  height: auto !important;
  background: transparent !important;
  min-width: 190px;
}
.boxs{
  width: auto;
}
.boxs .badge{
  position: unset;
  min-width: 100%;
  margin-top:10px ;
}
.boxs li{
  font-size: 14px;
  position: relative;
  text-align: left;
}
.boxs span{
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 600;
  float: right;
}
.boxs ul{
  padding: 0px;
  margin-bottom:5px;
}
span.textintworow{
  text-align: center !important;
  font-weight: 900;
  display: block !important;
  margin: auto !important;
  float: unset;
}
.card-title{
  span{
    font-size: 18px;
    font-weight: 500;
  }
}
.dateRange{
  width: 100%;
}
.report_name{
  text-transform: capitalize;
  font-weight: 400;
}
.table{
  display: inline-table !important;;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { socialvue } from "../../../../config/pluginInit.js"
import { analyticsTracker } from "../../../../FackApi/api/analytics/analytics_tracker.js"
import ColorCodes from "../../../../Utils/colorcodes.js"
import Multiselect from "vue-multiselect"
import moment from "moment"
import userPermission from "../../../../Utils/user_permission"

export default {
  name: "analyticsTrackerList",
  components: {
    Multiselect
  },
  data () {
    return {
      apiName: "analytics_tracker_list",
      cvSelectLabel: "Select ",
      cvCardTitle: "Analytics Report",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit analyticsTracker",
      cvAddModalHeader: "Add analyticsTracker",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Analytics Tracker List Response",
      showModelanalyticsTrackerAdd: false,
      showModelanalyticsTrackerDelete: false,
      sortBy: "name",
      sortDesc: false,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 30)), // From Last Month
          endDate: new Date(new Date().setDate(new Date().getDate()))
        },
        dateType: "month",
        action: "home"
      },
      columns: [
        { label: "Device Id", key: "device_id", class: "text-left align-text-top", sortable: true },
        { label: "date", key: "date", class: "text-left align-text-top", sortable: true },
        { label: "opens", key: "total_open_count", class: "text-left align-text-top", sortable: true },
        { label: "Unique User", key: "unique_user", class: "text-left align-text-top", sortable: true },
        { label: "opens/User ", key: "opu", class: "text-left align-text-top", sortable: true }
      ],
      analytics_trackerObjList: null,
      analytics_trackerEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      total_open_count_list: {},
      uniqueuser: {},
      totalhit: {},
      total_opens: 0,
      total_uniqueuser: 0,
      option: "",
      csModuleNameList: [],
      total_open_per_unique_user: 0,
      action_module_name: { module_name: "home" },
      refreshTotalCount: 0,
      delObj: null
    }
  },
  computed: {
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    },
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    await this.analytics_tracker_module_name()
    await this.getDateFilter()
    this.analytics_tracker_totalopencount()
    this.analytics_trackerList()
  },
  methods: {
    /**
     * getBgFor
     */
    getBgFor (index) {
      return ColorCodes.getBgColor(index)
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
     */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter.dateRange = storeDate.dateRange
      }
    },
    /**
     * getFormattedDate
     */
    getFormattedDate (date) {
      try {
        return moment(date).format("DD-MMM-YYYY")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDate() and Exception:", err.message)
      }
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * Calling Function as per whereFilter
     */
    async getAnalyticsListDateWise () {
      this.setDateFilter()
      this.analytics_tracker_totalopencount()
      this.analytics_trackerList()
    },
    setDateTypeFilterChange (datetype) {
      this.whereFilter.datetype = datetype
      this.analytics_tracker_totalopencount()
      this.analytics_trackerList()
    },
    setFilterChange (action) {
      this.whereFilter.action = action.module_name
      this.analytics_tracker_totalopencount()
      this.analytics_trackerList()
    },
    /**
     * analytics_trackerList
     */
    async analytics_trackerList () {
      try {
        let analyticsTrackerListResp = await analyticsTracker.analytics_trackerList(this, this.whereFilter)
        if (analyticsTrackerListResp.resp_status) {
          this.analytics_trackerObjList = analyticsTrackerListResp.resp_data.data
          this.totalRows = analyticsTrackerListResp.resp_data.count
        }
        else {
          this.toastMsg = analyticsTrackerListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at analytics_trackerList() and Exception:", err.message)
      }
    },

    /**
     * analytics_tracker_totalopencount
     */
    async analytics_tracker_totalopencount () {
      try {
        let analyticsTrackerListTotalopencountResp = await analyticsTracker.analytics_tracker_totalopencount(this, this.whereFilter)
        if (analyticsTrackerListTotalopencountResp.resp_status) {
          this.total_open_count_list = analyticsTrackerListTotalopencountResp.resp_data.data
          this.total_opens = 0
          this.total_uniqueuser = 0
          for (let open of this.total_open_count_list) {
            this.total_opens += open.total_open_count
            this.total_uniqueuser += open.unique_user
          }
          this.total_open_per_unique_user = (this.total_uniqueuser == 0) ? "0" : Math.round(this.total_opens / this.total_uniqueuser)
          this.refreshTotalCount += 1
        }
        else {
          this.toastMsg = analyticsTrackerListTotalopencountResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at analytics_tracker_totalopencount() and Exception:", err.message)
      }
    },
    /**
     * analytics_trackerAdd
     */
    analytics_trackerAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/analytics_tracker_add")
        }
        else {
          this.showModelanalyticsTrackerAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at analytics_trackerAdd() and Exception:", err.message)
      }
    },
    /**
     * showanalyticsTrackerDeleteDialog
     */
    showanalyticsTrackerDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelanalyticsTrackerDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showanalyticsTrackerDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * closeanalyticsTrackerAddModal
     */
    closeanalyticsTrackerAddModal (analyticsTrackerAddData) {
      try {
        if (analyticsTrackerAddData) {
          if (this.analytics_trackerObjList && this.analytics_trackerObjList.length >= 1) {
            let usrlObjLength = this.analytics_trackerObjList.length
            let lastId = this.analytics_trackerObjList[usrlObjLength - 1]["id"]
            analyticsTrackerAddData.id = lastId + 1
          }
          else {
            this.analytics_trackerObjList = []
            analyticsTrackerAddData.id = 11111
          }

          analyticsTrackerAddData.created_on = moment()
          this.analytics_trackerObjList.unshift(analyticsTrackerAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelanalyticsTrackerAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeanalytics_trackerAddModal() and Exception:", err.message)
      }
    },
    /**
     * analytics_tracker_module_name
     */
    async analytics_tracker_module_name () {
      try {
        let ModuleNameListResp = await analyticsTracker.module_name_list(this)
        if (ModuleNameListResp && !ModuleNameListResp.resp_status) {
          return false
        }
        this.csModuleNameList = ModuleNameListResp.resp_data.data
        this.csModuleNameList.splice(0, 0, { module_name: "home" })
      }
      catch (err) {
        console.error("Exception occurred at ModuleNameList() and Exception:", err.message)
      }
    }
  }
}
</script>
